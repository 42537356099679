
import { defineComponent, reactive, toRefs } from 'vue'
import ItemForm from './itemForm.vue'

export default defineComponent({
  name: 'operationManualItemShow',
  components: {
    ItemForm
  },
  setup (props, ctx) {
    const data = reactive({})

    return {
      ...toRefs(data)
    }
  }
})
